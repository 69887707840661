import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import PageCode from "containers/PageCode";
import PageCookiesPolicy from "../containers/PageCookiesPolicy";

export const pages: Page[] = [
  { path: "/cookies-policy", component: PageCookiesPolicy },
  // { path: "/privacy-policy", component: PagePrivacyPolicy },
  { path: "/code", component: PageCode },
  // { path: "/", component: PageHome },
];

export const apolloClient = new ApolloClient({
    uri: process.env.REACT_APP_HOST + '/bitrix/services/rbx.graphql/',
    cache: new InMemoryCache(),
});

const Routes = () => {
  return (
    <BrowserRouter basename="/">
        <ApolloProvider client={apolloClient}>
          <ScrollToTop />
          <SiteHeader />
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <Redirect from="*" to="/code" />
            <Route component={Page404} />
          </Switch>
          <Footer />
        </ApolloProvider>
    </BrowserRouter>
  );
};

export default Routes;
