import React, { FC, useEffect, useRef } from "react";
import NcModal from "shared/NcModal/NcModal";

export interface ModalCodesCheckHistoryProps {
    show: boolean;
    onCloseModalTransferToken: () => void;
    code: string;
    codeCheckHistory: string[];
}

const ModalCodesCheckHistory: FC<ModalCodesCheckHistoryProps> = ({
        show,
        onCloseModalTransferToken,
        code = '',
        codeCheckHistory = [],
    }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                const element: HTMLTextAreaElement | null = textareaRef.current;
                if (element) {
                    (element as HTMLTextAreaElement).focus();
                    (element as HTMLTextAreaElement).setSelectionRange(
                        (element as HTMLTextAreaElement).value.length,
                        (element as HTMLTextAreaElement).value.length
                    );
                }
            }, 400);
        }
    }, [show]);

    const renderContent = () => {
        return (
            <>
                <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                    Код проверялся:
                </h3>
                <div className="text-sm mt-2">
                    {codeCheckHistory.map((element, index) =>  <p>{element}</p>)}
                </div>
            </>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <NcModal
            isOpenProp={show}
            onCloseModal={onCloseModalTransferToken}
            contentExtraClass="max-w-lg"
            renderContent={renderContent}
            renderTrigger={renderTrigger}
            modalTitle={`История проверки кода ${code}`}
        />
    );
};

export default ModalCodesCheckHistory;
