import React, {FC, useEffect, useState} from "react";
import MainNav2Logged from "./MainNav2Logged";
import useScrollPosition from "../../hooks/useScrollPostition";
import usePopState from "../../hooks/usePopState";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {

    const scrollPositionLimit = 50;

    const scrollPosition = useScrollPosition();
    const [smallHeader, setSmallHeader] = useState<boolean>(false);

    useEffect(() => {

        if ((scrollPosition >= scrollPositionLimit || (smallHeader && scrollPosition >= scrollPositionLimit-48))) {
            setSmallHeader(true);
        } else {
            setSmallHeader(false);
        }
    }, [smallHeader, scrollPosition, window.location.pathname]);

    const isMainPage = window.location.pathname === '/';

  return (
    <div className={`nc-HeaderLogged top-0 w-full z-40 ${!isMainPage ? 'sticky' : 'fixed'}`}>
      <MainNav2Logged smallHeader={smallHeader} scrollPositionLimit={scrollPositionLimit} isMainPage={isMainPage} />
    </div>
  );
};

export default HeaderLogged;
