import { useEffect } from "react";
import { RouterProps, withRouter } from "react-router-dom";
import isSafariBrowser from "../utils/isSafariBrowser";

export interface ScrollToTopProps {
  history: RouterProps["history"];
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ history }) => {

    function makeScrollTop() {
        if (window.location.hash.length > 0) {
            let elementId = window.location.hash.split('l_')[1];
            let element = document.getElementById(elementId);
            if (element !== null) {
                window.scrollTo(0, element.offsetTop - 48 - 50);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }

  useEffect(() => {
    const unlisten = history.listen(() => {
        if (isSafariBrowser()) {
            setTimeout(() => {
                makeScrollTop();
            }, 700);
        } else {
            setTimeout(() => {
                makeScrollTop();
            }, 300);
        }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
