import React, { useState, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import LogoMobile from "../Logo/LogoMobile";

export interface MenuBarProps {smallHeader?: boolean; isMainPage?: boolean;}
const MenuBar: React.FC<MenuBarProps> = ({smallHeader = false, isMainPage = false}) => {
  const [isVisable, setIsVisable] = useState(false);

  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => setIsVisable(false);

  let strokeColor = (smallHeader && !isMainPage || isMainPage) ? '#fff' : '#7e838d';

  const renderContent = () => {
    return (
      <Transition appear show={isVisable} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={handleCloseMenu}
        >
          <div className="fixed left-0 top-0 bottom-0 w-full max-w-md md:w-auto z-max outline-none focus:outline-none">
            <React.Fragment>
              <Transition.Child
                as={Fragment}
                enter="transition duration-300 transform"
                enterFrom="opacity-100 -translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-300 transform"
                leaveFrom="opacity-100 translate-x-100"
                leaveTo="opacity-100 -translate-x-full"
              >
                <div className="z-20 relative">
                    <NavMobile onClickClose={handleCloseMenu} />
                </div>
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter=" duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave=" duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-neutral-900/60" />
              </Transition.Child>
            </React.Fragment>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <button
        onClick={handleOpenMenu}
        className="px-3 py-4 rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center"
      >
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.61 67.41" className="h-[30px] w-[30px]">
          <line y1="2.5" x2="95.61" y2="2.5" fill="none" stroke={strokeColor} strokeMiterlimit="10" strokeWidth="5"/>
          <line y1="33.04" x2="95.61" y2="33.04" fill="none" stroke={strokeColor} strokeMiterlimit="10" strokeWidth="5"/>
          <line y1="64.91" x2="95.61" y2="64.91" fill="none" stroke={strokeColor} strokeMiterlimit="10" strokeWidth="5"/>
        </svg>
      </button>

      {renderContent()}
    </>
  );
};

export default MenuBar;
