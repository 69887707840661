import React from "react";
import Heading from "../../components/Heading/Heading";
import bagsStudioFooterLogo from "../../images/bags-studio-footer-logo.svg";
import ButtonPrimary from "../Button/ButtonPrimary";
import {Link} from "react-router-dom";
import {CookieConsent} from "react-cookie-consent";
const Footer: React.FC = () => {

  return (
    <div className="nc-Footer relative" id="contacts">
        { /* <div className="mt-[4.2rem] lg:mt-[8rem]">
        <Heading isCenter={true}>
          Контакты
        </Heading>

        <div className="flex flex-col relative items-center justify-center text-[#7e838d] text-[13.85px] lg:text-[18.3px] font-story">
          <p className="max-w-4xl text-center px-8 md:px-0 mt-[0.9rem] lg:mt-[2.25rem]">
            +7 (495) 677-95-48
          </p>
          <p className="max-w-4xl text-center px-8 md:px-0 mt-[1.15rem] lg:mt-[3.55rem] leading-text-normal lg:leading-text-normal-pc">
            График работы: Пн-Чт: 9:00 - 18:00 <br/>
            Пт: 9:00 - 17:00
          </p>
          <p className="max-w-4xl text-center px-8 md:px-0 mt-[0.85rem] lg:mt-[1.55rem]">
            sales@store-bs.ru
          </p>
        </div>

        <div className="mt-5 lg:mt-[2.8rem]">
          <ButtonPrimary href="//store-bs.ru/" className="min-w-[123px] lg:min-w-[161.37px]" targetBlank={true} translate="relative left-1/2 -translate-x-1/2">Где купить?</ButtonPrimary>
        </div>
        <div className="mt-6">
          <ButtonPrimary href="//winner-bagz.ru/" className="min-w-[123px] lg:min-w-[161.37px]" targetBlank={true} translate="relative left-1/2 -translate-x-1/2">Купить оптом</ButtonPrimary>
        </div>
      </div> */}

      <div className="mt-[5.5rem] mb-[2.5rem] lg:mt-[9.5rem] text-center">
        <img src={bagsStudioFooterLogo} className="m-auto w-20 lg:w-28" alt="Логотип Bags Studio" />
      </div>

      <CookieConsent
          disableButtonStyles
          location="bottom"
          buttonText="Принять"
          cookieName="consent_cookie"
          style={{ background: "#ffffff", borderTop: ".5px solid #7e838d", color: "#000000" }}
          expires={150}
          buttonClasses="font-kozukaR bg-white border-[0.5px] border-black px-4 lg:px-[1.15rem] py-0 uppercase mr-5 text-[7.5px] hover:bg-black hover:text-white transition duration-300"
          buttonWrapperClasses="h-full ml-4 mb-4  md:ml-0 md:mb-0"
          contentClasses="font-thin text-xs"
      >
        Мы используем файлы cookie, разработанные нашими специалистами и третьими лицами, для анализа событий на нашем веб-сайте, что позволяет нам улучшать взаимодействие с пользователями и обслуживание.
        Продолжая просмотр страниц нашего сайта, вы принимаете условия его использования. Более подробные сведения смотрите в нашей <Link to="/cookies-policy" target="_blank" className="font-normal">Политике в отношении файлов Cookie</Link>.{" "}
      </CookieConsent>
    </div>
  );
};

export default Footer;
