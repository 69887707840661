import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/grooc-logo-gray.svg";
import logoLightImg from "images/logo-light.svg";
import twFocusClass from "../../utils/twFocusClass";

export interface LogoProps {
    img?: string;
    imgLight?: string;
    className?: string;

    onClick?: () => void;
    smallHeader?: boolean;
}

const LogoMobile: React.FC<LogoProps> = ({
    img = logoImg,
    imgLight = logoLightImg,
    className = "flex-shrink-0",
    onClick,
    smallHeader = false
}) => {
    return (
        <Link
            to="/"
            className={`ttnc-logo inline-block text-slate-600 ${className} ` + twFocusClass(false)}
            onClick={onClick ? onClick : void(1)}
        >
            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
            {img ? (
                <img
                    className={`transition-all duration-300 max-h-[38px] ${smallHeader ? 'hidden' : 'block'} ` + twFocusClass(false)}
                    src={img}
                    alt="Logo"
                />
            ) : (
                "Logo Here"
            )}
            {/*imgLight && smallHeader && (
                <img
                  className="max-h-8 sm:max-h-10"
                  src={imgLight}
                  alt="Logo-Light"
                />
              )*/}
        </Link>
    );
};

export default LogoMobile;
