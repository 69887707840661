import React, { InputHTMLAttributes } from "react";
import twFocusClass from "../../utils/twFocusClass";

export interface InputGroocProps extends InputHTMLAttributes<HTMLInputElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
}

const InputGrooc = React.forwardRef<HTMLInputElement, InputGroocProps>(
    (
        {
            className = "",
            sizeClass = "h-6 px-4 py-3 lg:py-5",
            fontClass = "text-[8px] lg:text-[18px] lg:leading-text-normal-pc font-normal",
            rounded = "rounded-2xl",
            children,
            type = "text",
            ...args
        },
        ref
    ) => {
        return (
            <input
                ref={ref}
                type={type}
                className={`block w-full border-b-[0.5px] lg:border-b-[2px] border-b-neutral-200 border-t-0 border-x-0 focus:border-primary-300 bg-white dark:border-neutral-700 ${rounded} ${fontClass} ${sizeClass} ${className} ` + twFocusClass(false)}
                {...args}
            />
        );
    }
);

export default InputGrooc;
