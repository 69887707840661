import React, {FC} from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import useScrollPosition from "../../hooks/useScrollPostition";

export interface MainNav2LoggedProps {smallHeader: boolean; scrollPositionLimit: number; isMainPage: boolean;}

const MainNav2Logged: FC<MainNav2LoggedProps> = ({smallHeader = false, scrollPositionLimit = 50, isMainPage = true}) => {
    const scrollPosition = useScrollPosition();

  const renderContent = () => {
    return (
      <div className={`${(smallHeader)  ? 'max-h-[37px]' : 'max-h-20'} relative transition-all duration-500 flex justify-between lg:hidden`}>
        <div className="flex items-center"> {/* lg:hidden lg:flex-1 */}
          <MenuBar smallHeader={smallHeader} isMainPage={isMainPage} />
        </div>

        <div className={`justify-center lg:justify-start lg:flex flex-1 ${smallHeader || isMainPage || (smallHeader && !isMainPage) ? 'hidden' : ''} items-center`}>
          <Logo className="flex-shrink-0" smallHeader={smallHeader} isMainPage={isMainPage} />
        </div>

        <div className={`${smallHeader && scrollPosition >= scrollPositionLimit ? 'flex lg:hidden -translate-x-6' : 'hidden'} transition duration-300 flex-1 justify-center text-white items-center`}>
          <span onClick={() => {window.scrollTo(0, 0)}} className="py-4 inline-block font-kozukaR text-xs uppercase cursor-pointer">Вверх</span>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-MainNav2Logged relative z-10 transition duration-500 ${(smallHeader) ? 'bg-header-mob' : 'bg-transparent'}`}>
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
