import React, {useState} from "react";
import { Helmet } from "react-helmet";
import {useLazyQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {CodeCheckerModuleQueryType, CodeCheckerModuleQueryVars} from "../graphql/types/checkCode.types";
import ModalCodesCheckHistory from "../components/ModalCodesCheckHistory";
import Heading from "../components/Heading/Heading";
import arrowsDownIcon from "../images/arrows-down.svg";
import groocCodeBadge from "../images/grooc-code-badge.jpg";
import InputGrooc from "../shared/Input/InputGrooc";
import photo1 from "../images/new-collection/1.jpg";
import photo2 from "../images/new-collection/2.jpg";
import photo3 from "../images/new-collection/3.jpg";
import photo4 from "../images/new-collection/4.jpg";
import photo5 from "../images/new-collection/5.jpg";
import photo6 from "../images/new-collection/6.jpg";
import photo7 from "../images/new-collection/7.jpg";
import photo8 from "../images/new-collection/8.jpg";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import mainBannerPc from "../images/banner-optimized.jpeg";
import Navigation from "../shared/Navigation/Navigation";


interface ICheckState {
    bgClass: string;
    textClass: string;
    heading: string;
    description: string;
}

interface ICheckStateObject {
    ['success']: ICheckState;
    ['warning']: ICheckState;
    ['error']: ICheckState;
    ['default']: ICheckState;
}
function PageCookiesPolicy() {

    return (
        <div className="nc-PageCode">
            <Helmet>
                <title>Политика в отношении файлов cookies | Grooc</title>
            </Helmet>

            <div className="container-fluid relative">
                <div
                    className={`nc-SectionHero relative`}
                    data-nc-id="SectionHero"
                >
                    <div className="relative bg-transparent">
                        <img className="hidden lg:block w-full max-h-full" src={mainBannerPc} alt="" />
                    </div>
                </div>
            </div>

            <div className="hidden lg:flex items-center justify-center container-fluid"> {/* sticky top-0 z-40 bg-[rgba(255,255,255,0.95)] m-0 p-0 w-full */}
                <Navigation />
            </div>

            <div className="relative container overflow-hidden">
                <Heading fontClass="mt-[3.13rem] lg:mt-[5.3rem] text-[13.85px] lg:text-[24.5px] font-story text-[#7e838d] uppercase" isCenter={true}>
                    Политика в отношении файлов cookies
                </Heading>

                    <div className="prose prose-sm text-justify text-[#7e838d] mx-auto">
                        Наш сайт, как и&nbsp;большинство других, использует файлы cookie и&nbsp;другие похожие технологии (веб-маяки, пиксельные тэги и т. п.),
                        чтобы предоставлять услуги, наиболее отвечающие вашим потребностям, а&nbsp;также накапливать статистическую информацию для анализа и&nbsp;
                        улучшения наших услуг и&nbsp;сайтов.
                        Настоящее Уведомление об&nbsp;использовании cookie-файлов (далее&nbsp;— «Уведомление») является частью Политики о&nbsp;конфиденциальности.
                        При использовании данного сайта, вы&nbsp;подтверждаете свое согласие на&nbsp;использование файлов cookie и&nbsp;других похожих технологий в&nbsp;
                        соответствии с&nbsp;настоящим Уведомлением.
                        Если вы&nbsp;не&nbsp;согласны, чтобы мы&nbsp;использовали данный тип файлов, вы&nbsp;должны соответствующим образом
                        установить настройки вашего браузера или не&nbsp;использовать наш сайт (подробнее&nbsp;— в&nbsp;разделе «Как управлять файлами cookie»).
                        <h3 >Что такое файл cookie и другие похожие технологии</h3>
                        Файл cookie представляет собой небольшой текстовый файл, сохраняемый на&nbsp;вашем компьютере, смартфоне или другом устройстве,
                        которое вы&nbsp;используете для посещения сайта.
                        Некоторые из&nbsp;посещенных страниц могут также собирать информацию, используя пиксельные тэги и&nbsp;веб-маяки,
                        представляющие собой электронные изображения, называемые одно-пиксельными (1×1) или пустыми GIF-изображениями.
                        Файлы cookie могут размещаться на&nbsp;вашем устройстве нами («собственные» файлы cookie) или другими операторами (файлы cookie «третьих лиц»).
                        Мы&nbsp;используем два вида файлов cookie на&nbsp;сайте: «cookie сессии» и&nbsp;«постоянные cookie».
                        Cookie сессии&nbsp;— это временные файлы, которые остаются на&nbsp;устройстве пока вы&nbsp;не&nbsp;покинете сайт.
                        Постоянные cookie остаются на&nbsp;устройстве в&nbsp;течение длительного времени или пока вы&nbsp;вручную не&nbsp;
                        удалите их (как долго cookie останется на&nbsp;вашем устройстве будет зависеть от&nbsp;продолжительности или «времени
                        жизни» конкретного файла и&nbsp;настройки вашего браузера).
                        На&nbsp;основании этих файлов собирается информация, помогающая оценить эффективность наших услуг и&nbsp;сайтов и&nbsp;
                        улучшить&nbsp;их. Например, чтобы узнать, когда вы&nbsp;посещаете наш сайт, как вы&nbsp;им&nbsp;пользуетесь,
                        как взаимодействуете с&nbsp;рассылкой по&nbsp;электронной почте и&nbsp;другим контентом, запомнить ваши предпочтения,
                        предоставить защищенное и&nbsp;безопасное обслуживание при онлайн-транзакциях и т. п.
                        Информация, собираемая такими файлами и&nbsp;технологиями, носит обобщенный характер и&nbsp;не&nbsp;связана с&nbsp;
                        идентификацией отдельных пользователей.
                        Тем не&nbsp;менее, при покупке вами у&nbsp;нас конкретных продуктов и&nbsp;услуг, мы&nbsp;можем использовать предоставленные
                        вами при сделке контактные данные в&nbsp;сочетании со&nbsp;сведениями, получаемыми благодаря файлам cookie и&nbsp;
                        похожим технологиям, чтобы проанализировать, как вы&nbsp;используете наш сайт и&nbsp;улучшить его.
                        Обработка таких контактных данных осуществляется нами в&nbsp;соответствии с&nbsp;Политикой о&nbsp;конфиденциальности.
                        <h3 >Виды файлов cookie, которые могут использоваться на нашем сайте</h3>
                        <b>Обязательные файлы cookie.</b> Эти файлы cookie позволяют вам посещать сайты и&nbsp;использовать их&nbsp;функции.
                        Их&nbsp;отключение усложнит функционирование сайтов и&nbsp;может сделать недоступными их&nbsp;функции и&nbsp;сервисы.
                        <br/><strong>Файлы cookie, относящиеся к&nbsp;производительности, эффективности и&nbsp;аналитике.</strong> Эти
                            файлы помогают нам понять, как пользователи взаимодействуют с&nbsp;нашим сайтом, показывают проблемы в&nbsp;его
                            работе (например, сообщения об&nbsp;ошибках), помогают измерять эффективность наших рекламных кампаний и&nbsp;оптимизировать
                            их&nbsp;для пользователей. <br/> <strong>Функциональные файлы cookie. </strong>Они помогают запоминать
                                индивидуальные предпочтения пользователей и&nbsp;персонализировать для них содержание сайта. <br/><strong>Рекламные
                                    файлы cookie.</strong> Они используются для доставки целевой рекламы и&nbsp;помогают нам оценивать эффективность
                                    рекламных кампаний. Мы&nbsp;можем делиться этими файлами с&nbsp;соответствующими рекламными системами и&nbsp;площадками,
                                    где размещается наша реклама. <br/> <strong>Электронная почта.</strong> Мы&nbsp;также можем использовать
                                        технологии, позволяющие отслеживать, открывали&nbsp;ли вы, прочитали или переадресовывали определенные сообщения,
                                        отправленные нами на&nbsp;вашу электронную почту. Это необходимо, чтобы сделать наши средства коммуникации более
                                        полезными для пользователя. Если вы&nbsp;не&nbsp;желаете, чтобы мы&nbsp;получали сведения об&nbsp;этом, вам нужно
                                        аннулировать подписку посредством ссылки «Отписаться» («Unsubscribe»), находящейся внизу соответствующей электронной
                                        рассылки. <br/> <strong>Кнопки доступа к&nbsp;социальным сетям. </strong>Они используются для того, чтобы
                                            пользователи могли поделиться ссылкой на&nbsp;страницу в&nbsp;социальных сетях или сделать электронную закладку.
                                            Данные кнопки являются ссылками на&nbsp;веб-сайты социальных сетей, принадлежащих третьим лицам, которые, в&nbsp;свою,
                                            очередь могут фиксировать информацию о&nbsp;вашей активности в&nbsp;интернете, в&nbsp;том числе на&nbsp;нашем сайте.
                                            Пожалуйста, ознакомьтесь с&nbsp;соответствующими условиями использования и&nbsp;политикой конфиденциальности таких
                                            сайтов для понимания того, как они используют ваши данные, и&nbsp;того, как можно отказаться от&nbsp;использования
                                            ими ваших данных или удалить их. <br/><strong>Сторонние
                                                веб-сервисы.</strong> Иногда на&nbsp;данном сайте мы&nbsp;используем
                                                сторонние веб-сервисы. Например, для отображения тех или иных элементов (изображения, видео, презентации и т. п.),
                                                организации опросов, оплаты продуктов и&nbsp;услуг через платежные системы и т. п. Как и&nbsp;в&nbsp;случае с&nbsp;кнопками
                                                доступа к&nbsp;социальным сетям, мы&nbsp;не&nbsp;можем препятствовать сбору этими сайтами или внешними доменами
                                                информации о&nbsp;том, как вы&nbsp;используете содержание сайта.
                                                <h3 >Как управлять файлами cookie?</h3>
                                                Большинство интернет-браузеров изначально настроены на&nbsp;автоматический прием файлов cookie.Вы&nbsp;можете
                                                изменить настройки вашего браузера таким образом, чтобы блокировать файлы cookie или предупреждать вас о&nbsp;том,
                                                когда они будут отправляться к&nbsp;вам на&nbsp;устройство (обратитесь к&nbsp;руководству использования конкретного
                                                браузера).  Если вы&nbsp;используете несколько устройств и (или) браузеров для доступа в&nbsp;интернет,
                                                соответствующие настройки должны быть изменены в&nbsp;каждом из&nbsp;них.
                                                <h3 >Изменение условий использования файлов cookie</h3>
                                                По&nbsp;собственному усмотрению мы&nbsp;можем периодически изменять настоящее Уведомление. Изменения в&nbsp;Уведомлении
                                                вступают в&nbsp;силу с&nbsp;момента публикации новой редакции на&nbsp;сайте. Факт использования вами нашего сайта
                                                после внесения соответствующих изменений означает ваше согласие на&nbsp;принятие новой редакции Уведомления.
                                                Дата последнего обновления и&nbsp;предыдущие редакции указаны в&nbsp;начале настоящего Уведомления.
                                                Мы&nbsp;рекомендуем периодически перечитывать настоящее Уведомление для ознакомления с&nbsp;его актуальной
                                                редакцией.
                    </div>

            </div>

            <div className="container-fluid mt-[4.5rem] lg:mt-[17.5rem] lg:mb-[10.7rem]">
                <div className="grid grid-rows-1 grid-cols-3 lg:grid-cols-8 gap-x-2 lg:px-2">
                    <img src={photo1} alt="" />
                    <img src={photo2} alt="" />
                    <img src={photo3} alt="" />
                    <img src={photo4} className="hidden lg:inline-block" alt="" />
                    <img src={photo5} className="hidden lg:inline-block" alt="" />
                    <img src={photo6} className="hidden lg:inline-block" alt="" />
                    <img src={photo7} className="hidden lg:inline-block" alt="" />
                    <img src={photo8} className="hidden lg:inline-block" alt="" />
                </div>
            </div>

        </div>
    );
}

export default PageCookiesPolicy;
